import { useEffect } from "react";
import { description, domain, game_name, link_canonical, title } from "./config";

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = `Contact Us | ${game_name}`;

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', `Have questions about ${game_name}? Reach out to our team for assistance, support, or general inquiries. We’d love to hear from you!`);
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = title;
            if (metaDescription) {
                metaDescription.setAttribute('content', description);
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', link_canonical);
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">{`Contact Us - ${game_name}`}</h1>
                    <br />
                    <span class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`We are always ready to listen and assist you! Your questions, feedback, and requests are extremely important to us. Whether you want to learn more about our products, services, or need help, feel free to reach out to us. ${domain} are committed to providing the best experience and will respond to you as quickly as possible.`}
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Contact Via Email
                    </h2>
                    <br />
                    <div class="des-game 2xl:text-lg lg:text-lg md:text-lg text-base">
                        For any inquiries, questions, or feedback about our products and services, please contact us via email: <a href={`mailto:support@${domain}`} className=" text-blue-500">{`support@${domain}`}</a>
                    </div>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        We strive to reply to all emails within 24-48 working hours. If you need urgent assistance, don’t hesitate to provide detailed information via email, and we will prioritize resolving your issue as soon as possible.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Feedback and Responses
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`We highly value feedback from our customers and users. Every piece of feedback helps us improve each day. Whether you have questions about our products, services, or any other inquiries, we are eager to hear from you. Please share your thoughts with us so we can serve you better.`}
                    </div>

                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base italic">
                        {`Thank you for visiting our website. We look forward to connecting and assisting you in the near`}
                    </div>
                </div>
            </div>
        </div>
    )
}