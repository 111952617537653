import { useEffect } from "react";
import { description, domain, game_name, link_canonical, title } from "./config";

export default function TermOfUse() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = `Term of Use | ${game_name}`;

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', `Read the Terms of Use for ${game_name}. By using our services, you agree to comply with these rules and guidelines. Learn about your rights and responsibilities.`);
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = title;
            if (metaDescription) {
                metaDescription.setAttribute('content', description);
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', link_canonical);
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-18vh)] text-[#383838] mb-10">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        {`Terms of Use for ${game_name}`}
                    </h1>
                    <br />
                    <span class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`Welcome to ${game_name}! These Terms of Use govern your access to and use of our website, games, services, and content (collectively referred to as "Services"). By accessing or using ${game_name}, you agree to comply with and be bound by these terms. Please read them carefully.`}
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Acceptance of Terms
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`By accessing or using ${game_name}, you agree to these Terms of Use. If you do not agree with any part of these terms, you are not authorized to access or use our Services.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. Changes to Terms
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`We may update these Terms of Use from time to time to reflect changes in our services, legal requirements, or business practices. Any changes will be posted on this page with an updated revision date. By continuing to use our Services, you agree to be bound by the revised terms.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Use of Services
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`You agree to use ${game_name} and its Services for lawful purposes only. You are prohibited from:`}
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                {`Engaging in any illegal activities or actions that may harm ${game_name} or other users.`}
                            </li>
                            <li>
                                Using our Services in any manner that violates applicable laws or regulations.
                            </li>
                            <li>
                                Attempting to gain unauthorized access to our Services, systems, or networks.
                            </li>
                            <li>
                                Uploading or distributing harmful software, viruses, or any malicious content.
                            </li>
                            <li>
                                {`Engaging in any behavior that disrupts, disables, or damages the functionality of ${game_name}.`}
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. Account Registration and Security
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`In order to access certain features of ${game_name}, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account. If you suspect any unauthorized access or use of your account, you must notify us immediately.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. User-Generated Content
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`You may have the ability to create, post, or share content on ${game_name} (such as comments, reviews, and forum posts). By submitting content, you grant ${game_name} a worldwide, royalty-free, non-exclusive license to use, display, and distribute your content in connection with our Services. You are solely responsible for the content you post, and you agree not to post anything that:`}
                    </div>
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                {`Violates the rights of others, including intellectual property or privacy rights.`}
                            </li>
                            <li>
                                Is offensive, defamatory, obscene, or harmful in any way.
                            </li>
                            <li>
                                Promotes illegal activities or encourages harm.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        6. Intellectual Property
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`All content on ${game_name}, including but not limited to graphics, logos, images, text, software, and game assets, is owned by ${game_name} or its licensors and is protected by intellectual property laws. You may not use, copy, distribute, or modify any content without prior written permission from ${game_name}, except as expressly permitted by these Terms of Use.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        7. Limitation of Liability
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`${game_name} makes no warranties regarding the availability, functionality, or accuracy of our Services. We are not liable for any damages arising from your use or inability to use ${game_name}, including but not limited to direct, indirect, incidental, or consequential damages. You use ${game_name} at your own risk.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        8. Termination of Access
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`We reserve the right to suspend or terminate your access to ${game_name} at our sole discretion, without notice, if you violate these Terms of Use or engage in conduct that we deem inappropriate. Upon termination, you must cease all use of our Services.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        9. Privacy Policy
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`Your use of ${game_name} is also governed by our [Privacy Policy], which explains how we collect, use, and protect your personal information. By using our Services, you consent to the practices outlined in our Privacy Policy.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        10. Contact Us
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`If you have any questions or concerns regarding these Terms of Use, please contact us at:`}
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                        }}>
                            <li>
                                Email: <a href={`mailto:support@${domain}`} className=" text-blue-500">{`support@${domain}`}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}