import { useEffect } from "react";
import { description, domain, game_name, link_canonical, title } from "./config";

export default function Policy() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = `Privacy Policy | ${game_name}`;

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', `Learn about ${game_name}'s privacy policy. We are committed to protecting your personal information and explain how we collect, use, and safeguard your data.`);
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = title;
            if (metaDescription) {
                metaDescription.setAttribute('content', description);
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', link_canonical);
            }
        };
    }, []);

    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        {`Privacy Policy for ${game_name}`}
                    </h1>
                    <br />
                    <span class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`At ${game_name}, we take your privacy seriously and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, store, and protect your data when you visit our website or use our services. By accessing or using ${game_name}, you agree to the practices described in this policy.`}
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Information We Collect
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        We collect two types of information from users: Personal Information and Non-Personal Information.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-xl lg:text-xl md:text-xl text-sm !font-medium">
                        {`1. Personal Information: This refers to information that can identify you as an individual. It includes, but is not limited to:`}
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Name
                            </li>
                            <li>
                                Email address
                            </li>
                            <li>
                                Payment information (if applicable)
                            </li>
                            <li>
                                Any other data you voluntarily provide when you create an account or contact us.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-xl lg:text-xl md:text-xl text-sm !font-medium">
                        {`2. Non-Personal Information: This refers to data that does not directly identify you. It may include:`}
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Browser type
                            </li>
                            <li>
                                Device type
                            </li>
                            <li>
                                IP address
                            </li>
                            <li>
                                Cookies and tracking technologies data
                            </li>
                            <li>
                                Information about how you interact with our website and services.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How We Use Your Information
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`The information we collect is used to provide and improve your experience with ${game_name}, and for the following purposes:`}
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                        }}>
                            <li>
                                To provide services: We use your personal information to create accounts, process transactions, and offer customer support.
                            </li>
                            <li>
                                To improve our services: We analyze non-personal data to enhance user experience and website functionality.
                            </li>
                            <li>
                                {`To send communications: We may use your email address to send you updates, newsletters, or promotional offers related to ${game_name}. You can opt out at any time.`}
                            </li>
                            <li>
                                {`For legal compliance: We may use your information to comply with legal obligations, enforce our terms of service, and protect the rights, property, and safety of ${game_name}, its users, and the public.`}
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How We Protect Your Information
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`We use industry-standard security measures to protect your personal information. This includes encryption, firewalls, and secure servers. While we take reasonable steps to protect your data, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Cookies and Tracking Technologies
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`We use cookies and similar tracking technologies to enhance your experience on our website, analyze traffic, and personalize content. Cookies are small files stored on your device that help us remember your preferences and recognize you on subsequent visits.
You can control cookie settings in your browser, but disabling cookies may affect your ability to use certain features of ${game_name}.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Third-Party Links
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`${game_name} may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review their privacy policies before providing any personal information.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Your Data Rights
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You have the right to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}>
                            <li>
                                Access: Request a copy of the personal data we hold about you.
                            </li>
                            <li>
                                Update: Correct or update your personal information.
                            </li>
                            <li>
                                Delete: Request the deletion of your personal data, subject to certain legal exceptions.
                            </li>
                            <li>
                                Opt-Out: Opt out of marketing communications at any time by clicking "unsubscribe" in any email or contacting us directly.
                            </li>
                        </ul>
                        To exercise these rights, please contact us at <a href={`mailto:support@${domain}`} className=" text-blue-500">{`support@${domain}`}</a>.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Children's Privacy
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`${game_name} is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under 13. If we discover that we have inadvertently collected personal information from a child under 13, we will take steps to delete that information.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Changes to This Privacy Policy
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. Please review this policy periodically to stay informed about how we protect your data.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Contact Us
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:`}
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}>
                            <li>
                                Email Address: <a href={`mailto:support@${domain}`} className=" text-blue-500">{`support@${domain}`}</a>
                            </li>
                        </ul>
                        By clearly outlining how user data is handled, stored, and protected, this Privacy Policy helps ensure compliance with privacy regulations and builds trust with your audience. It also provides transparency regarding cookies, third-party links, and user rights.
                    </div>
                </div>
            </div>
        </div>
    )
}