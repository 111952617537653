import { useEffect } from "react";
import { description, domain, game_name, link_canonical, title } from "./config";

export default function DMCA() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = `DCMA Policy | ${game_name}`;

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', `Learn about our DMCA takedown policy and how to report copyright infringements on ${game_name}. We respect intellectual property rights and are committed to resolving issues promptly.`);
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = title;
            if (metaDescription) {
                metaDescription.setAttribute('content', description);
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', link_canonical);
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        {`DMCA Takedown Notice for ${game_name}`}
                    </h1>
                    <br />
                    <span class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`At ${game_name}, we respect the intellectual property rights of others and are committed to ensuring that our platform complies with the Digital Millennium Copyright Act (DMCA). If you believe that your copyrighted material has been used or posted on our site without permission, please follow the steps below to submit a DMCA takedown notice.`}
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How to Submit a DMCA Takedown Notice:
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base">
                        If you are the owner of copyrighted material or are authorized to act on behalf of the copyright owner and you believe that your copyrighted work has been infringed upon, please send a notice that includes the following information:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                <strong>Your Contact Information:</strong> Include your full name, address, telephone number, and email address.
                            </li>
                            <li>
                                <strong>Identification of the Infringing Material:</strong> Provide a description of the copyrighted work you claim has been infringed. Please include the URL(s) of the material that you believe is infringing on your copyright.
                            </li>
                            <li>
                                <strong>Statement of Good Faith:</strong> A statement that you believe in good faith that the material is not authorized by the copyright owner, its agent, or the law.
                            </li>
                            <li>
                                <strong>Statement of Accuracy:</strong> A statement, under penalty of perjury, that the information provided in your notice is accurate, and that you are the copyright owner or are authorized to act on behalf of the copyright owner.
                            </li>
                            <li>
                                <strong>Your Signature:</strong> Include an electronic or physical signature of the person authorized to act on behalf of the copyright owner.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Send the DMCA Takedown Notice:
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        Please send your DMCA takedown notice to the following:
                    </div>
                    <div class="des-game 2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Email Address: <a href={`mailto:support@${domain}`} className=" text-blue-500">{`support@${domain}`}</a>
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Counter-Notice:
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        If you believe that the content in question has been removed or disabled by mistake or misidentification, you may file a counter-notice with us. A valid counter-notice must include the following:
                    </div>
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}>
                            <li>
                                Your contact information <span className="italic">(name, address, phone number, and email)</span>.
                            </li>
                            <li>
                                Identification of the material that was removed or disabled.
                            </li>
                            <li>
                                A statement under penalty of perjury that you believe the material was removed in error or that you have the authorization to use it.
                            </li>
                            <li>
                                Your signature.
                            </li>
                        </ul>

                    </div>
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        Once we receive a valid counter-notice, we may reinstate the removed content within 10-14 business days unless the copyright owner files a lawsuit to prevent the reinstatement.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Limitation of Liability:
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`${game_name} is not liable for any infringement of intellectual property. The responsibility for ensuring that the content uploaded and shared on our platform does not violate any copyright laws rests with the users.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Changes to the DMCA Policy:
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`${game_name} reserves the right to modify or update this DMCA policy at any time without prior notice. Any changes will be posted on this page.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Contact Us:
                    </h2>
                    <br />
                    <div class="des-game 2xl:text-lg lg:text-lg md:text-lg text-base">
                        <span>If you have any questions or concerns about the DMCA process or wish to discuss a copyright-related issue, please contact us at</span> <a href={`mailto:support@${domain}`} className=" text-blue-500">{`support@${domain}`}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}