import { useEffect } from "react";
import { description, game_name, link_canonical, title } from "./config";

export default function About() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = `About Us | ${game_name}`;
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', `Learn about the journey of ${game_name}, a game that became a global phenomenon. From its humble beginnings to its place in modern gaming, discover what makes it timeless.`);
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = title;
            if (metaDescription) {
                metaDescription.setAttribute('content', description);
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', link_canonical);
            }
        };
    }, []);
    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="container 2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">{`About ${game_name}`}</h1>
                    <br />
                    <span class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`${game_name} is a retro arcade-style game that has captivated players for decades. With its simple yet addictive gameplay, ${game_name} has evolved over time, gaining widespread popularity across various platforms. In this article, we’ll explore the history, development, and enduring appeal of ${game_name}, and why it remains a favorite for both nostalgic players and new generations.`}
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        {`The History of ${game_name}`}
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`${game_name} emerged in the early days of the gaming industry when simplicity and creativity in gameplay were key. Over the years, the game has been refined and reimagined by numerous developers, bringing it to a global audience.`}
                    </div>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`From its origins on classic gaming consoles to modern adaptations on mobile phones and online platforms, ${game_name} has successfully adapted to changing technology and player preferences, ensuring its relevance across generations.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Modern Versions and Adaptations
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`Since its inception, ${game_name} has undergone various updates and adaptations. While the original versions often featured basic graphics and mechanics, modern editions incorporate enhanced visuals, multiplayer modes, and innovative twists on gameplay.`}
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        {`Why ${game_name} Remains Popular`}
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`The enduring appeal of ${game_name} lies in its simplicity and universal charm. It resonates with nostalgic players who cherish classic games while remaining accessible to new audiences.`}
                    </div>
                    <br />
                    <div class="des-game 2xl:text-lg lg:text-lg md:text-lg text-base">
                        Key reasons for its lasting popularity include:
                    </div>
                    <div class="des-game 2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}>
                            <li>
                                Ease of Play: Simple mechanics make it approachable for players of all skill levels.
                            </li>
                            <li>
                                Engaging Challenge: Increasing difficulty keeps the gameplay exciting and rewarding.
                            </li>
                            <li>
                                {`Nostalgia Factor: For longtime fans, ${game_name} evokes memories of the early days of gaming, creating a connection that modern games often cannot replicate.`}
                            </li>
                        </ul>
                    </div>
                    <div class="des-game 2xl:text-lg lg:text-lg md:text-lg text-base">
                        {`${game_name} stands as a testament to the power of simple yet captivating gameplay. Whether revisiting a beloved classic or experiencing it for the first time, ${game_name} continues to provide a memorable and entertaining gaming experience. Explore the various versions available today and rediscover what makes ${game_name} a timeless legend!`}
                    </div>
                </div>
            </div>
        </div>
    )
}